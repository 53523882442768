import React, {
// FormEvent
} from 'react';
import {
  ArticleSettings,
} from 'src/infrastructure/gatsby/types/article-settings';
import {
  jetBlack,
} from '@shipae/components-sandbox/component/colors';
import Breadcrumbs from '@shipae/components-sandbox/component/breadcrumbs';
import { graphql, useStaticQuery } from 'gatsby';
import ReactMarkdown from 'markdown-to-jsx';

// import {
//   SubscribeFormWide,
//   SubscribeForm,
// } from '@shipae/components-sandbox/component/subscribe-form';
import FacebookIcon
  from '@shipae/components-sandbox/component/icons/facebook';
import TwitterIcon
  from '@shipae/components-sandbox/component/icons/twitter';
import LinkedinIcon
  from '@shipae/components-sandbox/component/icons/linkedin';

import RichText from '@shipae/components-sandbox/component/rich-text';
import { calculateBreadcrumbs } from 'src/views/utils/calculate-breadcrumbs';

import { useTranslation } from 'src/views/misc/localization';
import {
  Background,
  BackgroundImage,
  BlankSpace,
  Meta,
  ShareLink,
  Sheet,
  SocialBar,
  StickyReference,
  Title,
  Date,
  PrevNextPosts,
  PreviousPost,
  NextPost,
  StyledArrowLeft,
  StyledArrowRight,
  PrevNextHorizontalFlex,
} from './styled';
import SmartLink from '../smart-link';
import gloabalReactMdOptions from '../shared/react-makdown-options';
import { ROUTES } from '../../constants/ROUTES';

type Props = {
  article: ArticleSettings;
  listPageTitle: string;
  listPageUrl: string;
  nextArticleSettings: null | ArticleSettings;
  previousArticleSettings: null | ArticleSettings;
}

type Data = {
  site: {
    siteMetadata: {
      siteUrl: string;
    }
  }
}

const DetailArticle: React.FC<Props> = ({
  article,
  listPageTitle,
  listPageUrl,
  nextArticleSettings,
  previousArticleSettings,
}) => {
  const { locale, t } = useTranslation();
  const data = useStaticQuery<Data>(graphql`
    query SiteUrlQuery {
      site {
        siteMetadata {
          siteUrl
        }
      }
    }
  `);

  return (
    <Background>
      {article.image?.localFile?.childImageSharp?.fluid
        ? (
          <BackgroundImage
            fluid={article.image?.localFile?.childImageSharp?.fluid}
            alt={article.image?.alternativeText || ''}
            objectFit="cover"
            objectPosition="center top"
          />
          )
        : <BlankSpace />}
      <Sheet>
        <StickyReference>
          <SocialBar>
            <ShareLink
              rel="noopener noreferrer"
              href={`https://www.facebook.com/sharer/sharer.php?u=${
                data.site.siteMetadata.siteUrl
              }${ listPageUrl }${ article.slug }`}
            >
              <FacebookIcon color={jetBlack()} width={3} />
            </ShareLink>
            <ShareLink
              rel="noopener noreferrer"
              href={`https://twitter.com/intent/tweet?url=${
                data.site.siteMetadata.siteUrl
              }${ listPageUrl }${
                article.slug
              }`}
            >
              <TwitterIcon color={jetBlack()} width={3} />
            </ShareLink>
            <ShareLink
              rel="noopener noreferrer"
              href={`https://www.linkedin.com/shareArticle?mini=true&url=${
                data.site.siteMetadata.siteUrl
              }${ listPageUrl }${
                article.slug
              }&title=${
                article.title
              }&summary=${
                article.excerpt
              }&source=${
                data.site.siteMetadata.siteUrl
              }`}
            >
              <LinkedinIcon color={jetBlack()} width={3} />
            </ShareLink>
          </SocialBar>
        </StickyReference>
        <Meta>
          <Breadcrumbs
            home={{
              label: t('home'),
              url: ROUTES[locale].HOME,
            }}
            linkComponent={SmartLink}
            links={calculateBreadcrumbs({
              parentTitle: t(listPageTitle),
              parentPath: listPageUrl,
              title: article.title,
            })}
          />
          <Date>
            {article.publishDate}
          </Date>
        </Meta>
        <Title>{article.title}</Title>
        <RichText>
          <ReactMarkdown options={{
            ...gloabalReactMdOptions,
            forceBlock: false,
          }}
          >
            {article.content || ''}
          </ReactMarkdown>
        </RichText>
      </Sheet>
      {/* <SubscribeFormWideEx
        emailValue="email value here"
        onEmailChange={(val: string): void => console.log(val)}
        onSubmit={(e: FormEvent): void => {
          alert('Submitted'); // eslint-disable-line
          e.preventDefault();
        }}
      />
      <SubscribeFormEx
        emailValue="email value here"
        onEmailChange={(val: string): void => console.log(val) }
        onSubmit={(e: FormEvent): void => {
          alert('Submitted'); // eslint-disable-line
          e.preventDefault();
        }}
      /> */}
      <PrevNextPosts>
        {previousArticleSettings && (
          <PreviousPost
            href={
            `${ listPageUrl }/${ previousArticleSettings.slug }`
            }
            rel="prev"
          >
            <PrevNextHorizontalFlex>
              <StyledArrowLeft />
              <span>
                {t('previous')}
              </span>
            </PrevNextHorizontalFlex>
            {previousArticleSettings.title}
          </PreviousPost>
        )}
        {nextArticleSettings && (
          <NextPost
            href={
            `${ listPageUrl }/${ nextArticleSettings.slug }`
            }
            rel="next"
          >
            <PrevNextHorizontalFlex>
              <span>
                {t('next')}
              </span>
              <StyledArrowRight />
            </PrevNextHorizontalFlex>
              {nextArticleSettings.title}
          </NextPost>
        )}
      </PrevNextPosts>
    </Background>
  );
};

export default DetailArticle;
