import React, { FC } from 'react';
import { PageProps } from 'gatsby';
import Layout from 'src/views/components/layout/default';
import { Article } from 'src/infrastructure/gatsby/types/article';
import DetailArticle from 'src/views/components/detail-article';
import {
  ArticlePageContext,
} from 'src/infrastructure/gatsby/node/create-pages/articles/types';
import SEO from 'src/views/components/seo-tags';
import {
  LabelsAndMessages,
} from 'src/infrastructure/gatsby/types/label-and-messages';
import PageContextProvider from '../context';

type DataProps = {
  article: Article
  labelsAndMessages: Partial<LabelsAndMessages>;
}

const Template: FC<
  PageProps<DataProps, ArticlePageContext>
> = (props) => {
  const {
    location,
    pageContext,
    data: {
      article: {
        articleSettings,
        seoSettings,
        translationLinks = [],
      },
      labelsAndMessages,
    },
  } = props;

  if (!seoSettings) {
    throw new Error('No SEO settings provided!');
  }

  return (
    <PageContextProvider
      locale={pageContext.locale}
      translationLinks={translationLinks}
      title={articleSettings.title}
      pageSettings={{
        id: articleSettings.id,
        language: pageContext.locale,
        slug: articleSettings.slug,
        subpath: pageContext.listPageUrl,
        publishStatus: articleSettings.publishStatus,
        parentPageTitle: pageContext.listPageTitle,
        parentPagePath: pageContext.listPageUrl,
      }}
      labelsAndMessages={labelsAndMessages}
    >
      {/* Todo: CREATE LayoutWithSidebar */}
      <Layout location={location}>
        <SEO
          metaTitle={seoSettings.metaTitle}
          metaDescription={seoSettings.metaDescription}
          metaIndex={seoSettings.metaIndex}
          metaFollow={seoSettings.metaFollow}
          canonicalUrl={seoSettings.canonicalUrl}
          location={location}
          pageType="website"
          translationLinks={translationLinks}
          socialPreviewImage={articleSettings.image?.localFile?.publicURL}
        />
        <DetailArticle
          article={articleSettings}
          listPageTitle={pageContext.listPageTitle}
          listPageUrl={pageContext.listPageUrl}
          nextArticleSettings={pageContext.nextArticleSettings || null}
          previousArticleSettings={pageContext.previousArticleSettings || null}
        />
      </Layout>
    </PageContextProvider>
  );
};

export default Template;
