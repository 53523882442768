import SmartLink from 'components/smart-link';
import GatsbyImage from 'gatsby-image/withIEPolyfill';
import { FluidObject } from 'gatsby-image';

import {
  cleanWhite,
  jetBlack,
  paleGrey,
  silverGrey,
  blackGrey,
  g500,
} from '@shipae/components-sandbox/component/colors';
import { fonts, media } from 'src/views/theme';
import styled, { css } from 'styled-components';
import {
  ReactComponent as ArrowRight,
} from '@shipae/components-sandbox/component/static-icons/arrow-right.svg';

export const Background = styled.section`
  background-color: ${ paleGrey() };
`;

interface BgImageProps {
  fluid: FluidObject | FluidObject[];
}

export const BackgroundImage = styled(GatsbyImage)<BgImageProps>`
  height: 47rem;
  width: 100%;
  background-color: ${ jetBlack() };
`;

export const BlankSpace = styled.div`
  height: 20rem;
`;

export const Sheet = styled.article`
  position: relative;
  max-width: 97rem;
  margin-left: auto;
  margin-right: auto;
  margin-top: -17rem;
  background-color: ${ cleanWhite() };
  padding: 4.6rem 5rem;
  border-radius: 2rem;
  box-shadow: inset 0 0 4px ${ silverGrey() };
  margin-bottom: 3rem;

  ${ media.md } {
    width: 95%;
  }

  ${ media.sm } {
    padding: 2.5rem 2rem;
  }
`;

export const Meta = styled.div`
  display: flex;
  justify-content: space-between;

  & > *:nth-child(2) {
    flex-shrink: 0;
  }
`;

export const Date = styled.div`
  text-transform: uppercase;
  ${ fonts.bodyl300 };
  color: ${ blackGrey() };
`;

export const Title = styled.h1`
  ${ fonts.displaym300 };
  margin: 3.5rem 0;

  ${ media.sm } {
    ${ fonts.displays300 };
  }
`;

// export const SubscribeFormWideEx = styled(SubscribeFormWide)`
//   max-width: 97rem;
//   margin: 4rem auto;

//   ${ media.md } {
//     width: 95%;
//   }
//   ${ media.sm } {
//     display: none;
//   }
// `;

// export const SubscribeFormEx = styled(SubscribeForm)`
//   display: none;
//   ${ media.sm } {
//     display: block;
//     width: 95%;
//     margin: 4rem auto;
//   }
// `;

export const StickyReference = styled.div`
  position: absolute;
  top: 22rem;
  left: -7rem;
  height: calc(100% - 35rem);
`;

export const SocialBar = styled.nav`
  display: flex;
  flex-direction: column;
  position: -webkit-sticky;
  position: sticky;
  top: 10rem;
  left: 0;
`;

export const ShareLink = styled(SmartLink)`
  &:not(:last-child) {
    margin-bottom: 2rem;
  }
  &, &:active, &:visited {
    text-decoration: none;
  }
`;

export const PrevNextPosts = styled.ul`
  display: flex;
  justify-content: space-between;

  max-width: 115rem;
  margin-left: auto;
  margin-right: auto;
  padding: 8rem 3.5rem 6rem;

  ${ media.sm } {
    flex-direction: column;
  }
`;

const sharedPrevNextPostCss = css`
  display: flex;
  flex-direction: column;
  flex-basis: 40rem;
  color: ${ g500() };

  ${ media.sm } {
    flex-basis: auto;

    &:first-child {
      margin-bottom: 3rem;
    }
  }

  ${ fonts.displayxs300 }

  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;

export const PreviousPost = styled(SmartLink)`
  ${ sharedPrevNextPostCss }
`;
export const NextPost = styled(SmartLink)`
  ${ sharedPrevNextPostCss }
  align-items: flex-end;
  text-align: right;
`;

export const StyledArrowRight = styled(ArrowRight)`
  width: 4rem;

  transform: rotate(0);

  [dir=rtl] & {
    transform: rotate(180deg);
  }
`;
export const StyledArrowLeft = styled(ArrowRight)`
  width: 4rem;

  transform: rotate(180deg);

  [dir=rtl] & {
    transform: rotate(0);
  }
`;

export const PrevNextHorizontalFlex = styled.div`
  display: flex;

  text-transform: uppercase;
  align-items: center;

  span {
    &:first-child {
      margin-right: 2rem;
    }
    &:last-child {
      margin-left: 2rem;
    }

    ${ fonts.bodyl300 }
  }
`;
